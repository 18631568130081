import {baseUrl} from "./baseUrl";

export const UserUrls = {
    userLogin:baseUrl +"/api/auth/login",
    userRegister:baseUrl + "/api/auth/register-user",
    AllUserCostomers:baseUrl + "/api/v1/list-users",
    userById: baseUrl +"/api/v1/user/",
    userMessages: baseUrl +"/api/v1/feedbacks",
}

export const  BooksUrls = {
    allBooks: baseUrl +"/api/v1/books",
    UserSubscribedBooks:baseUrl + "ht/userBooks/",
    addBooks:baseUrl + "/api/v1/books",
    BookSubscription:baseUrl + "/api/v1/book-subscriptions",
    AllSubscriptions: baseUrl +"/api/v1/list-book-subscriptions",
    SubscriptionById:baseUrl + "/api/v1/book-subscription/",
    SingleBook:baseUrl +"/api/v1/book/"
}

export const RoleUrls = {
    dashboardStatistics:baseUrl + "/api/v1/dashboard-statistics",
    changeStatus: "http://localhost:8010/api/change-status",
    reset: "http://localhost:8010/api/reset",
    get: "http://localhost:8010/api/",
    getAll: "http://localhost:8010/api/list"
}


export const ApplicationUrls = {
    createOrUpdate: "http://localhost:8010/api/application/create",
    changeStatus: "http://localhost:8010/api/application/change-status",
    reset: "http://localhost:8010/api/application/reset",
    get: "http://localhost:8010/api/application/",
    getAll: "http://localhost:8010/api/application/list",
    enroll: "http://localhost:8010/api/application/enroll",
}
