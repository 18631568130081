import React, { useState, useEffect } from "react";
import pdf from "./Netwoc perfomance on transMed Report.pdf";
import "./pdfV.css";
import { baseUrl } from "../../../utils/baseUrl";

import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


// Create Document Component
export default function PdfViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    const handleBeforePrint = event => {
      if (event.ctrlKey && event.keyCode == 80) {
        event.preventDefault();
        return false
      }
    };

    window.addEventListener('keyup', handleBeforePrint);
    window.addEventListener('keydown', handleBeforePrint);

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('keyup', handleBeforePrint);
      window.removeEventListener('keydown', handleBeforePrint);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
            style={{ '.pdf-viewer-toolbar-item-download': { display: 'none' }, '.react-pdf__Page__textContent': {userSelect: 'none'}}}
            fileUrl={props.pdfUrl}
        />
      </Worker>
    </div>
  );
};

// // export default PdfViewer;
// import { useEffect, useRef } from "react";
// // import './pdffile.css'
//
// export default function PdfViewer(props) {
//   console.log(props);
//   const containerRef = useRef(null);
//
//   useEffect(() => {
//     const container = containerRef.current;
//
//     let instance, PSPDFKit;
//
//     (async function () {
//       PSPDFKit = await import("pspdfkit");
//
//       const defaultItems = PSPDFKit.defaultToolbarItems;
//       console.log(defaultItems);
//
//       instance = await PSPDFKit.load({
//         // Container where PSPDFKit should be mounted.
//         container,
//
//         // The document to open.
//         document: props.pdfUrl,
//
//
//
//         // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
//         baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
//
//         // Use the render method to customize the UI and return null for the toolbar component
//         render: ({ Toolbar }) => {
//           return null;
//         },
//       });
//
//       const items = instance.toolbarItems;
//       instance.setToolbarItems(
//         items.filter((item) => {
//             if(item.type !== "export-pdf" && item.type !== "print" && item.type !== "document-editor" ){
//               return true
//             }
//
//         })
//       );
//     })();
//
//     return () => PSPDFKit && PSPDFKit.unload(container);
//   }, []);
//
//   return <div className="pdffile" ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
// }
//
