import React, { useState } from "react";
import { Select, Typography, Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel, } from "@material-tailwind/react";
import { UserUrls } from "../../../utils/Config";
import { useDataFetch } from "../../../hooks/DataHook";
import UserEdtModal from "../Components/UserEdtModal";
import { useFormPost } from "../../../hooks/FormHook";
import loading from '../../../Assets/output-onlinegiftools.gif';


const renderDateTime = (dateString) => {
    const dateTime = new Date(dateString);
    return dateTime.toLocaleDateString();
  };
  

export const  UserContactMessages = () => {
  const fetcher = useDataFetch();
  const [usersMessageList, setusersMessageList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowUser, setRowUser] = useState();
  const [isLoading, setisLoading] = React.useState(false);



  const loadData = async () => {
    setisLoading(true)
    const response = await fetcher.fetch({ url: UserUrls.userMessages });
    console.log(response);
    if (response) {
        setusersMessageList(response.data);
      setisLoading(false)
    }
  };


  React.useEffect(() => {
    loadData();
  }, []);
  if (isLoading) {
    return(
        <div>
        <div className="text-center px-4 py-8 " style={{minHeight:'300px'}}>
            <img src={loading} class="mx-auto w-10 " style={{backgroundColor:'transparent', alignItems:'center'}}/>
            <Typography className="mt-2" muted small>
                  loading messages..
              </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Tabs value={1}>
              <TabsHeader>
                <Tab key={1} value={1}>
                  {"User Feedbacks"}
                </Tab>
              </TabsHeader>
              

               
            </Tabs>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
               
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    message
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    sent at
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {usersMessageList?.map((person) => (
                  <tr key={person.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            className="h-10 w-10 rounded-full"
                            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                            alt=""
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {person.full_name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {person.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {person.message}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {renderDateTime(person.created_at)}
                      </div>
                    </td>
                   
                 
                  </tr>
                 
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};